import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { HostFactory } from "../../ethereum/HostFactory";
import { bytesTohash } from "../../utils";
import { sendTransaction } from "../../components/TransactionModal/sendTransaction";

import { ethers } from "ethers";

export default function HostPage() {
  // const [spin, setSpin] = useState(true);
  const [details, setdetails] = useState({
    Name: "",
    IP: "",
    peerID: "",
    getAPI: "",
    setAPI: "",
    Description: "",
  });
  const [month, setMonth] = useState<number>(null);
  const [ishost, setIshost] = useState(false);
  const [iswallet, setIswallet] = useState(false);

  const [month1, setMonth1] = useState(null);
  const [earning, setEarning] = useState("");
  const [earning1, setEarning1] = useState(null);
  const [fetch, setFetch] = useState(false);
  const [show, setShow] = useState(false);

  const [files, setFiles] = useState([]);

  const fetchBalance = async () => {
    setFetch(true);
    setShow(false);
    const earn = (await window.HostInstance.Balance(month1)).toNumber();
    setEarning1(earn);
    setFetch(false);
    setShow(true);
  };

  const withdrawBalance = async () => {
    await sendTransaction(
      window.HostInstance,
      "withdrawFund",
      [month1],
      "You have successfully withdraw amount "
    );
  };

  const refreshFiles = async () => {
    await sendTransaction(
      window.HostInstance,
      "setActiveFiles",
      [],
      "You have successfully refresh files "
    );
  };

  const fetchDetail = async () => {
    if (iswallet) {
      const Address = window?.wallet.address
        ? window.wallet.address
        : await window.wallet.getAddress();
      const Hostaddress = await window.ESCloudInstance.host(Address);
      if (Hostaddress !== ethers.constants.AddressZero) {
        setIshost(true);
      } else {
        return;
      }
      window.HostInstance = HostFactory.connect(
        Hostaddress,
        window.providerESN
      );
      // setSpin(true)
      const ipfshash = await window.HostInstance.details();
      const data = await axios.get(
        `https://ipfs.eraswap.cloud/ipfs/${bytesTohash(ipfshash)}`
      );
      console.log(data);
      setdetails(data.data);

      //Earning
      const start = (await window.HostInstance.START()).toString();

      const date: number = Math.floor(Date.now() / 1000);

      const activeMonth = Math.floor((date - parseInt(start)) / 2629744);
      // console.log(start,date,activeMonth);
      setMonth(activeMonth);
      const e = (await window.HostInstance.Balance(activeMonth)).toString();
      setEarning(e);

      const files = await window.HostInstance.getActiveFiles();
      setFiles(files);
    }
    // setSpin(false)
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (window.wallet) {
        setIswallet(true);
        clearInterval(intervalId);
      } else setIswallet(false);
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await fetchDetail();
      } catch {
        // setExist(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iswallet]);

  return (
    <section className="services section-bg">
      {!iswallet ? (
        <div
          className="order-1 order-lg-2 hero-img section-title mx-auto"
          data-aos="zoom-in"
        >
          <img
            src={process.env.PUBLIC_URL + "/assets/img/connectwallet.png"}
            className="img-fluid animated"
            alt=""
          />
        </div>
      ) : (
        <React.Fragment>
          {!ishost ? (
            <div className="order-1 order-lg-2 hero-img section-title mx-auto">
              <i
                className="fa fa-globe"
                style={{ fontSize: "300px", color: "#6610f2" }}
                aria-hidden="true"
              ></i>
              <br />
              <div className="section-title">
                <h2>You haven't join as Host. </h2>
                <p>
                  if You want to become a host and start earning. Join as host
                  now ...
                </p>
                <br />
                <Link className="purple-btn ml-2 p-3" to="join-host">
                  Join as Host
                </Link>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-lg-3">
                <Card className=" section-bg">
                  <Card.Header
                    style={{ backgroundColor: "#6610f2", color: "white" }}
                  >
                    {details?.Name}
                  </Card.Header>
                  <Card.Body>
                    <Card.Title>Details</Card.Title>
                    <Card.Text>
                      <ul className="list-group">
                        <li className="list-group-item">
                          <b>IP : </b> {details.IP}
                        </li>
                        <li className="list-group-item">
                          <b>get API : </b> {details.getAPI}
                        </li>
                        <li className="list-group-item">
                          <b>set API : </b> {details.setAPI}
                        </li>
                        <li className="list-group-item">
                          <b>Peer ID : </b> {details.peerID}
                        </li>
                        <li className="list-group-item">
                          <b>Description : </b> {details.Description}
                        </li>
                      </ul>
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer className="text-muted text-center">
                    {/* {Hostaddress} */}
                  </Card.Footer>
                </Card>
              </div>

              <div className="col-9">
                <div className="col-6 btn-purple card mx-5 p-3 my-5">
                  <h4>Your Earnings</h4>
                  <div className="btn-purple">
                    <p>
                      You have {month} {"  "} month to join host{" "}
                    </p>
                    <p>You Earn {earning} ES this month</p>
                    <h5>See Previous Months earning</h5>
                    <div className="input-group">
                      <input
                        className="form-control"
                        type="number"
                        placeholder=""
                        onChange={(e) => setMonth1(e.target.value)}
                      />
                      <div className="input-group-btn">
                        <button className="btn btn-info" onClick={fetchBalance}>
                          {fetch ? "Please wait " : "See"}
                        </button>
                      </div>
                    </div>
                    {show ? (
                      <div>
                        <p>
                          Earning at MonthID : {month1} is : {earning1}
                        </p>
                        <button
                          className="purple-btn"
                          onClick={withdrawBalance}
                        >
                          Withdraw Now{" "}
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="section card  mx-5 p-3 my-5">
                  <h4>Your Active files</h4>
                  {files.map((ele) => (
                    <li>{bytesTohash(ele)}</li>
                  ))}
                  <div className="btn-purple">
                    <p>You can hard refresh on blockchain </p>
                    <br />
                    <br />
                    <button className="purple-btn p-3 mr-2">
                      <i className="fa fa-download"></i> Download CSV
                    </button>
                    <button className="purple-btn p-3" onClick={refreshFiles}>
                      <i className="fa fa-refresh"></i> Refresh
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    </section>
  );
}
