import React from "react";
// import { Link } from "react-router-dom";
export class Homepage extends React.Component {
  render() {
    return (
      <div>
        <section id="hero" className="d-flex align-items-center">
          <div className="container-fluid" data-aos="fade-up">
            <div className="row justify-content-center">
              <div className="col-xl-5 col-lg-6 pt-3 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
                <h1>ESCloud</h1>
                <p className="text-white">
                  ESCloud is a IPFS based decentralized & peer-to-peer network
                  for storing and sharing data in a distributed file
                  system.ESCloud is making more decentralized and secure all
                  eraswap based DApps.
                </p>
                <p className="text-white">
                  EScloud leverages blockchain technology to create a data
                  storage marketplace that is more robust and more affordable
                  than traditional cloud storage providers.
                </p>
                <div>
                  <a href="#about" className="btn-get-started scrollto">
                    Become Host
                  </a>{" "}
                  <a href="#about" className="btn-get-started scrollto">
                    Store Your Files
                  </a>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-6 order-1 order-lg-2 hero-img"
                data-aos="zoom-in"
                data-aos-delay="150"
              >
                <img
                  src="assets/img/hero-img.png"
                  className="img-fluid animated"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        <main id="main">
          <section id="about" className="about ">
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-6 order-1 order-lg-2 "
                  data-aos="zoom-in"
                  data-aos-delay="150"
                >
                  <img
                    src="assets/img/why-es.png"
                    className="img-fluid mt80"
                    alt=""
                  />
                </div>
                <div
                  className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content"
                  data-aos="fade-right"
                >
                  <h3>Why ESCloud </h3>
                  <ul className="mt40">
                    <li>
                      <i className="icofont-check-circled"></i> Users buy and
                      sell unused storage using the network's cryptocurrency
                      (ES).{" "}
                    </li>
                    <li>
                      <i className="icofont-check-circled"></i> All data stored
                      by Dapps on Eraswap will be stored on ESCloud
                    </li>
                    <li>
                      <i className="icofont-check-circled"></i> No Spammers and
                      Malicious nodes can’t be part of ESCloud, There is PoS
                      based KYC
                    </li>
                    <li>
                      <i className="icofont-check-circled"></i>Get Secure your
                      Data as you pay
                    </li>
                    <li>
                      <i className="icofont-check-circled"></i> Protect the
                      flies by encrypting them up and having them copied between
                      various hosts. This will help ensure the safety of the
                      file.
                    </li>
                    <li>
                      <i className="icofont-check-circled"></i> Renters pay
                      using ES tokens, which can also be mined and traded.{" "}
                    </li>
                    <li>
                      <i className="icofont-check-circled"></i> In ESCloud you
                      have to pay amount of storage that you are using only. We
                      won’t charging for your unused amount. Existing storage
                      provider (like S3) forced you to buy a fixed storage
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section id="services" className="services section-bg">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>How it works</h2>
                <p>
                  There are two main components in ESCloud – the renters and the
                  hosts. The renters can pay hosts in ES tokens to lease storage
                  capacities. They are also free to determine the storage fees
                  directly from the hosts.
                </p>
              </div>
              <div className="row">
                <div
                  className="col-lg-6 col-md-6 d-flex align-items-stretch"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <div className="icon-box iconbox-blue">
                    <div className="icon">
                      <svg
                        width="100"
                        height="100"
                        viewBox="0 0 600 600"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke="none"
                          stroke-width="0"
                          fill="#f5f5f5"
                          d="M300,521.0016835830174C376.1290562159157,517.8887921683347,466.0731472004068,529.7835943286574,510.70327084640275,468.03025145048787C554.3714126377745,407.6079735673963,508.03601936045806,328.9844924480964,491.2728898941984,256.3432110539036C474.5976632858925,184.082847569629,479.9380746630129,96.60480741107993,416.23090153303,58.64404602377083C348.86323505073057,18.502131276798302,261.93793281208167,40.57373210992963,193.5410806939664,78.93577620505333C130.42746243093433,114.334589627462,98.30271207620316,179.96522072025542,76.75703585869454,249.04625023123273C51.97151888228291,328.5150500222984,13.704378332031375,421.85034740162234,66.52175969318436,486.19268352777647C119.04800174914682,550.1803526380478,217.28368757567262,524.383925680826,300,521.0016835830174"
                        ></path>
                      </svg>
                      <i className="bx bx-server"></i>
                    </div>
                    <h4>
                      <a href="/">Host</a>
                    </h4>
                    <p>
                      <i className="icofont-check-circled"></i> Promote their
                      storage resources and the quality of service that they
                      provide
                    </p>
                    <p>
                      <i className="icofont-check-circled"></i> Have the right
                      to refuse rent storage to a particular client if they feel
                      that the data is too sensitive, ethically unacceptable, or
                      illegal.
                    </p>
                  </div>
                </div>
                <div
                  className="col-lg-6 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
                  data-aos="zoom-in"
                  data-aos-delay="200"
                >
                  <div className="icon-box iconbox-orange ">
                    <div className="icon">
                      <svg
                        width="100"
                        height="100"
                        viewBox="0 0 600 600"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke="none"
                          stroke-width="0"
                          fill="#f5f5f5"
                          d="M300,582.0697525312426C382.5290701553225,586.8405444964366,449.9789794690241,525.3245884688669,502.5850820975895,461.55621195738473C556.606425686781,396.0723002908107,615.8543463187945,314.28637112970534,586.6730223649479,234.56875336149918C558.9533121215079,158.8439757836574,454.9685369536778,164.00468322053177,381.49747125262974,130.76875717737553C312.15926192815925,99.40240125094834,248.97055460311594,18.661163978235184,179.8680185752513,50.54337015887873C110.5421016452524,82.52863877960104,119.82277516462835,180.83849132639028,109.12597500060166,256.43424936330496C100.08760227029461,320.3096726198365,92.17705696193138,384.0621239912766,124.79988738764834,439.7174275375508C164.83382741302287,508.01625554203684,220.96474134820875,577.5009287672846,300,582.0697525312426"
                        ></path>
                      </svg>
                      <i className="bx bx-file"></i>
                    </div>
                    <h4>
                      <a href="/">Renter</a>
                    </h4>
                    <p>
                      <i className="icofont-check-circled"></i> Protect the
                      flies by Encrypting them up and having them copied between
                      various hosts. This will help ensure the safety of the
                      file.
                    </p>
                    <p>
                      <i className="icofont-check-circled"></i> Pay the hosts
                      more than the asked fees to ensure preferential treatment,
                      such as faster upload speeds and granting storage
                      requests.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="faq" className="faq">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>How to join ESCloud network as a host </h2>
                <p>
                  ESCloud IPFS network can be easily set up on Ubuntu OS.
                  <br /> you can use Cloud services to connected with our
                  network.
                </p>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="faq-list">
                    <ul>
                      <li data-aos="fade-up">
                        <i className="bx bx-check-circle icon-help"></i>{" "}
                        <a
                          data-toggle="collapse"
                          className="collapse"
                          href="#faq-list-1"
                        >
                          Step 1{" "}
                          <i className="bx bx-chevron-down icon-show"></i>
                          <i className="bx bx-chevron-up icon-close"></i>
                        </a>
                        <div
                          id="faq-list-1"
                          className="collapse"
                          data-parent=".faq-list"
                        >
                          <p>
                            <b>Getting started with EC2 :</b> First, pop on over
                            to aws.amazon.com/free/ and Create a Free Account .
                            When you hit Continue, it’ll take you to a page to
                            fill in your contact information. I setup a Personal
                            account.
                          </p>
                          <img
                            src="assets/img/step1.png"
                            alt=""
                            className="img-fluid mt10 mb10"
                          />
                        </div>
                      </li>
                      <li data-aos="fade-up" data-aos-delay="200">
                        <i className="bx bx-check-circle icon-help"></i>{" "}
                        <a
                          data-toggle="collapse"
                          href="#faq-list-2"
                          className="collapsed"
                        >
                          Step 2<i className="bx bx-chevron-down icon-show"></i>
                          <i className="bx bx-chevron-up icon-close"></i>
                        </a>
                        <div
                          id="faq-list-2"
                          className="collapse"
                          data-parent=".faq-list"
                        >
                          <p>
                            <b>
                              create our basic free-tier AWS EC2 instance :{" "}
                            </b>
                            Click on Services {">"}
                            Compute {">"} EC2 . You’ll end up on a page listing
                            the Amazon EC2 resources you are currently using.
                            Right now, it’s probably just 1 Security Group . So
                            let’s change that, and add an EC2 instsance by
                            clicking on Launch Instance .
                          </p>
                          <img
                            src="assets/img/step2.png"
                            alt=""
                            className="img-fluid mt10 mb10"
                          />
                          <img
                            src="assets/img/step2.1.png"
                            alt=""
                            className="img-fluid mt10 mb10"
                          />
                        </div>
                      </li>
                      <li data-aos="fade-up" data-aos-delay="300">
                        <i className="bx bx-check-circle icon-help"></i>{" "}
                        <a
                          data-toggle="collapse"
                          href="#faq-list-3"
                          className="collapsed"
                        >
                          Step 3<i className="bx bx-chevron-down icon-show"></i>
                          <i className="bx bx-chevron-up icon-close"></i>
                        </a>
                        <div
                          id="faq-list-3"
                          className="collapse"
                          data-parent=".faq-list"
                        >
                          <p>
                            {" "}
                            <b>
                              {" "}
                              create our basic free-tier AWS EC2 instance :{" "}
                            </b>{" "}
                          </p>
                          <img
                            src="assets/img/step3.png"
                            alt=""
                            className="img-fluid mt10 mb10"
                          />
                        </div>
                      </li>
                      <li data-aos="fade-up" data-aos-delay="400">
                        <i className="bx bx-check-circle icon-help"></i>{" "}
                        <a
                          data-toggle="collapse"
                          href="#faq-list-4"
                          className="collapsed"
                        >
                          Step 4<i className="bx bx-chevron-down icon-show"></i>
                          <i className="bx bx-chevron-up icon-close"></i>
                        </a>
                        <div
                          id="faq-list-4"
                          className="collapse"
                          data-parent=".faq-list"
                        >
                          <p>
                            Add User data: now we’re setting up user data which
                            will run on instance at the time of deployment. In{" "}
                            <b>Configure Instance</b> you have to add user data
                            in <b>advanced Details </b>
                          </p>
                          <p>
                            Go here and copy code inside setUp.sh from{" "}
                            <a
                              href="https://github.com/KMPARDS/esCloud-spec/blob/master/setUP.h"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              here{" "}
                            </a>
                          </p>
                          <p>
                            Then click on <b>Next:Add Storage </b>
                          </p>
                          <img
                            src="assets/img/step4.png"
                            alt=""
                            className="img-fluid mt10 mb10"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="faq-list">
                    <ul>
                      <li data-aos="fade-up">
                        <i className="bx bx-check-circle icon-help"></i>{" "}
                        <a
                          data-toggle="collapse"
                          className="collapse"
                          href="#faq-list-5"
                        >
                          Step 5{" "}
                          <i className="bx bx-chevron-down icon-show"></i>
                          <i className="bx bx-chevron-up icon-close"></i>
                        </a>
                        <div
                          id="faq-list-5"
                          className="collapse"
                          data-parent=".faq-list"
                        >
                          <p>
                            <b>Add Storage : </b>Add storage according to your
                            need
                          </p>
                          <img
                            src="assets/img/step5.png"
                            alt=""
                            className="img-fluid mt10 mb10"
                          />
                        </div>
                      </li>
                      <li data-aos="fade-up">
                        <i className="bx bx-check-circle icon-help"></i>{" "}
                        <a
                          data-toggle="collapse"
                          className="collapse"
                          href="#faq-list-6"
                        >
                          Step 6{" "}
                          <i className="bx bx-chevron-down icon-show"></i>
                          <i className="bx bx-chevron-up icon-close"></i>
                        </a>
                        <div
                          id="faq-list-6"
                          className="collapse"
                          data-parent=".faq-list"
                        >
                          <p>
                            {" "}
                            <b>Configure Security groups :</b> Next, skip over
                            Add Tags , and go straight to Configure Security
                            Group . By default, SSH connections on port 22 are
                            enabled from any source. This is probably fine,
                            though restricting access to known IP addresses is
                            probably a good idea. We’ll add some rules here (and
                            maybe change the group name).
                          </p>
                          <img
                            src="assets/img/step5.1.png"
                            alt=""
                            className="img-fluid mt10 mb10"
                          />
                        </div>
                      </li>
                      <li data-aos="fade-up" data-aos-delay="200">
                        <i className="bx bx-check-circle icon-help"></i>{" "}
                        <a
                          data-toggle="collapse"
                          href="#faq-list-7"
                          className="collapsed"
                        >
                          Step 7{" "}
                          <i className="bx bx-chevron-down icon-show"></i>
                          <i className="bx bx-chevron-up icon-close"></i>
                        </a>
                        <div
                          id="faq-list-7"
                          className="collapse"
                          data-parent=".faq-list"
                        >
                          <p>
                            <b>(optional) </b>This will bring up the dialog to
                            create a key new pair. So select create a new key
                            pair , name it something useful like ipfs , and
                            download the pem file ( your browser might
                            automatically append .txt to the downloaded file
                            name, so you’ll want to remove this before
                            subsequent steps ). Then click Launch Instances . If
                            you hadn’t setup billing alerts previously, you can
                            do it now by clicking on the links.
                          </p>
                          <img
                            src="assets/img/step6.png"
                            alt=""
                            className="img-fluid mt10 mb10"
                          />
                        </div>
                      </li>
                      <li data-aos="fade-up" data-aos-delay="300">
                        <i className="bx bx-check-circle icon-help"></i>{" "}
                        <a
                          data-toggle="collapse"
                          href="#faq-list-8"
                          className="collapsed"
                        >
                          Step 8<i className="bx bx-chevron-down icon-show"></i>
                          <i className="bx bx-chevron-up icon-close"></i>
                        </a>
                        <div
                          id="faq-list-8"
                          className="collapse"
                          data-parent=".faq-list"
                        >
                          <p>
                            While you wait for your instance to launch, you can
                            View Instances , or read up on{" "}
                            <a
                              href="https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/AccessingInstances.html?icmpid=docs_ec2_console"
                              target=""
                            >
                              how to connect to your Linux instance .{" "}
                            </a>
                          </p>
                          {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
                          <p>Hurray 🎉🎉🎉</p>
                          <p>You have successfully join our network </p>
                          <p>
                            If you opened up your gateway, you should now be
                            able to browse to its address in a browser, and ask
                            for the docs directory:
                          </p>
                          <p>
                            http://ec2-xx-xxx-xxx-xx.us-xxxx-2.compute.amazonaws.com:8080/ipfs/QmS4ustL54uo8F
                            zR9455qaxZwuMiUhyvMcX9Ba8nUH4uVv , or anything else
                            accessible on the decentralized web.
                          </p>
                          <img
                            src="assets/img/step7.png"
                            alt=""
                            className="img-fluid mt10 mb10"
                          />
                        </div>
                      </li>
                    </ul>
                    <div id="host" className="btn-cloud-center">
                      <a href="/joint-host" className="btn-cloud-txt">
                        Join as a Host
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="features" className="features">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>Features</h2>
              </div>
              <div className="row">
                <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column align-items-lg-center">
                  <div
                    className="icon-box mt-5 mt-lg-0"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <i className="bx bx-check-shield "></i>
                    <h4>Secure & private </h4>
                    <p>
                      The ESCloud network is secure by default. All files are
                      encrypted with a secret-key on the client side. This
                      secret-key is included in the file handler that is
                      returned when any file is uploaded. Encryption of files on
                      the client side means that the independent storage nodes
                      hosting the files(actually just pieces of an encrypted
                      file) have no way of knowing the contents which ensures
                      provider independent security and complete privacy of
                      content.
                    </p>
                  </div>
                  <div
                    className="icon-box mt-5"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <i className="bx bx-shape-square"></i>
                    <h4>Distributed</h4>
                    <p>
                      Every file on ESCloud is distributed on whole network,it
                      can be access from any peers of ES network. Renter can
                      choose one or more host to store their files according to
                      its feasibility.
                    </p>
                  </div>
                  <div
                    className="icon-box mt-5"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <i className="bx bx-network-chart"></i>
                    <h4>Decentralized</h4>
                    <p>
                      Decentralization is essentially the absence of a single
                      service provider or central point of failure. Absence of
                      an entity that can solely control costs and disbursement
                      of services on a Network for the purposes of cornering the
                      highest benefits
                    </p>
                  </div>
                </div>
                <div
                  className="image col-lg-6 order-1 order-lg-2 "
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <img
                    src="assets/img/features.svg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="row ">
                <div
                  className="icon-box col-lg-4 mt-5"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <i className="bx bx-rocket"></i>
                  <h4>Fast</h4>
                  <p>
                    Thanks to distributed architecture of IPFS .IPFS 0.5.0 makes
                    finding and sharing data in the network much faster, thanks
                    to large improvements to our DHT logic. More efficient
                    routing and providing—already 2-3x faster—lowers bandwidth
                    usage and background traffic.
                  </p>
                </div>
                <div
                  className="icon-box col-lg-4 mt-5"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <i className="bx bx-search"></i>
                  <h4>Transparent</h4>
                  <p>
                    All upload/download actions begin as requests made to the
                    network which are logged as transactions on the blockchain.
                    The size of the file is one of the details logged by the
                    client SDK as part of an initrequest transaction
                  </p>
                </div>
                <div
                  className="icon-box col-lg-4 mt-5"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <i className="bx bx-cog"></i>
                  <h4>DApps intregration</h4>
                  <p>You can secure your DApp with the help of ESCloud </p>
                </div>
              </div>
            </div>
          </section>
          <section id="services" className="services section-bg">
            <div className="container" data-aos="fade-up">
              <div className="row">
                <div
                  className="col-lg-6 col-md-6 d-flex align-items-stretch"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <div className="icon-box iconbox-blue">
                    <div className="icon">
                      <svg
                        width="100"
                        height="100"
                        viewBox="0 0 600 600"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke="none"
                          stroke-width="0"
                          fill="#f5f5f5"
                          d="M300,521.0016835830174C376.1290562159157,517.8887921683347,466.0731472004068,529.7835943286574,510.70327084640275,468.03025145048787C554.3714126377745,407.6079735673963,508.03601936045806,328.9844924480964,491.2728898941984,256.3432110539036C474.5976632858925,184.082847569629,479.9380746630129,96.60480741107993,416.23090153303,58.64404602377083C348.86323505073057,18.502131276798302,261.93793281208167,40.57373210992963,193.5410806939664,78.93577620505333C130.42746243093433,114.334589627462,98.30271207620316,179.96522072025542,76.75703585869454,249.04625023123273C51.97151888228291,328.5150500222984,13.704378332031375,421.85034740162234,66.52175969318436,486.19268352777647C119.04800174914682,550.1803526380478,217.28368757567262,524.383925680826,300,521.0016835830174"
                        ></path>
                      </svg>
                      <i className="bx bx-question-mark "></i>
                    </div>
                    <h4>
                      <a href="/">Existing Problem with centralization</a>
                    </h4>
                    <p>
                      The Internet is the biggest working model of a
                      decentralized and distributed network but everything built
                      on it or for it is largely centralized. The data on the
                      internet is especially centralized with large entities
                      constantly vying to gain a larger piece of the data pie.
                    </p>
                  </div>
                </div>
                <div
                  className="col-lg-6 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
                  data-aos="zoom-in"
                  data-aos-delay="200"
                >
                  <div className="icon-box iconbox-orange ">
                    <div className="icon">
                      <svg
                        width="100"
                        height="100"
                        viewBox="0 0 600 600"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke="none"
                          stroke-width="0"
                          fill="#f5f5f5"
                          d="M300,582.0697525312426C382.5290701553225,586.8405444964366,449.9789794690241,525.3245884688669,502.5850820975895,461.55621195738473C556.606425686781,396.0723002908107,615.8543463187945,314.28637112970534,586.6730223649479,234.56875336149918C558.9533121215079,158.8439757836574,454.9685369536778,164.00468322053177,381.49747125262974,130.76875717737553C312.15926192815925,99.40240125094834,248.97055460311594,18.661163978235184,179.8680185752513,50.54337015887873C110.5421016452524,82.52863877960104,119.82277516462835,180.83849132639028,109.12597500060166,256.43424936330496C100.08760227029461,320.3096726198365,92.17705696193138,384.0621239912766,124.79988738764834,439.7174275375508C164.83382741302287,508.01625554203684,220.96474134820875,577.5009287672846,300,582.0697525312426"
                        ></path>
                      </svg>
                      <i className="bx bx-bulb"></i>
                    </div>
                    <h4>
                      <a href="/">Solution</a>
                    </h4>
                    <p>
                      EScloud provides you a decentralized network and
                      peer-to-peer network for storing and sharing data in a
                      distributed file system.
                    </p>
                    <p>
                      ESCloud is a private network protocol based on IPFS where
                      users can buy and sell unused storage using the network's
                      cryptocurrency (ES).
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    );
  }
}
