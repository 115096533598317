import { CustomProvider } from "eraswap-sdk/dist";
import { ethers } from "ethers";
// import { CouponDappCouponDappFactory } from "./CouponDappCouponDappFactory";
import { EsCloudFactory } from "./EsCloudFactory";
import { HostFactory } from "./HostFactory";

// const nodeUrl = process.env.REACT_APP_NODE_URL || 'https://node2.testnet.eraswap.network/';

// window.provider = new ethers.providers.JsonRpcProvider(
//   "https://mainnet.eraswap.network"
// );
// window.providerESN = new ethers.providers.JsonRpcProvider(
//   "https://mainnet.eraswap.network"
// );
window.provider = new CustomProvider("mainnet");
window.providerESN = new CustomProvider("mainnet");

window.ESCloudInstance = EsCloudFactory.connect(
  "0xEB54134883A7A50bE712c4Ce75c65Bf1694C1522", //0x7314d48fCf1534d586dFbb1a4b67CA12663B6f12
  window.providerESN
);

window.HostInstance = HostFactory.connect(
  "0x4a8726221fC5f97b691c1D8B83742508fDfF15C6",
  window.providerESN
);

// export const BetInst = BetFactory.connect(addresses.Bet, providerESN);

// export const providerESN = new ethers.providers.JsonRpcProvider(nodeUrl);

console.log("custom provider", window.providerESN);
