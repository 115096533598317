import React, { useState } from "react";
import EventFilter from "./EventFilter";
import HostApprove from "./HostApprove";

export default function Admin() {
  const [dispute, setDispute] = useState(false);
  return (
    <section id="faq" className="faq">
      <div className="container" data-aos="fade-up">
        <div className="row">
          <div className="col-lg-12 col-md-9">
            <div className="container-white">
              <div className="joint-cloud-flex">
                <img
                  src="assets/img/logocopy.png"
                  className="img-joint"
                  alt="logo"
                />
              </div>
              <br />
              <div className="btn-purple">
                <button
                  className="purple-btn"
                  onClick={() => setDispute(false)}
                >
                  Host request
                </button>
                <button
                  className="purple-btn ml-2"
                  onClick={() => setDispute(true)}
                >
                  {" "}
                  Disputes{" "}
                </button>
              </div>
              {!dispute ? <HostApprove /> : <EventFilter />}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
