import React from "react";
// import logo from "./logo.svg";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Navbar } from "./components/Navbar";
import { Footer } from "./components/Footer";
import { Homepage } from "./containers/Homepage";
import { JointHost } from "./containers/JoinHost/JoinHost";

import "./App.css";
import Admin from "./containers/Admin/Admin";
import All from "./containers/All/All";
import HostPage from "./containers/HostPage/HostPage";
import HostPanel from "./containers/HostPanel/HostPanel";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Switch>
          <Route exact path="/" component={Homepage} />
          <Route exact path="/join-host" component={JointHost} />
          <Route exact path="/Admin" component={Admin} />
          <Route exact path="/All" component={All} />
          <Route exact path="/Host/:Hostaddress" component={HostPage} />
          <Route exact path="/HostPanel" component={HostPanel} />
        </Switch>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
