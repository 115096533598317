import axios from "axios";
import React, { useEffect, useState, useCallback } from "react";
import { Buffer } from "buffer";
import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { HostFactory } from "../../ethereum/HostFactory";
import { bytesTohash, hashTobytes } from "../../utils";
import { FileWithPath, useDropzone } from "react-dropzone";
import Swal from "sweetalert2";
import { sendTransaction } from "../../components/TransactionModal/sendTransaction";
import { resolveIPFS } from "../JoinHost/ipfs";
import { ethers } from "ethers";
import Recent from "./Recent";

export default function HostPage() {
  const { Hostaddress } = useParams() as { Hostaddress: string };
  const [fileBuffer, setFileBuffer] = useState<any>();

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          // Do whatever you want with the file contents
        };
        reader.readAsArrayBuffer(file);
        reader.onloadend = async () => {
          console.log(reader.result);
          const buf = Buffer.from(reader.result);
          setFileBuffer(buf);
          setSpin(false);
          console.log(fileBuffer);
        };
      });
    },
    [fileBuffer]
  );

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    onDrop,
  });
  const [spin, setSpin] = useState(true);
  const [upload, setUpload] = useState(false);
  const [details, setdetails] = useState({
    Name: "",
    IP: "",
    peerID: "",
    getAPI: "",
    setAPI: "",
    Description: "",
  });
  const [next, setnext] = useState(false);

  window.HostInstance = HostFactory.connect(Hostaddress, window.providerESN);

  const uploadFile = async () => {
    try {
      setUpload(true);
      if (!fileBuffer) {
        Swal.fire("Oops...!", `Please add file first`, "error");
        setUpload(false);
        return;
      }

      const ipfs = resolveIPFS(details.setAPI);
      let asyncGenerator = await ipfs.add(fileBuffer);
      console.log(asyncGenerator);
      console.log("buffer : ", fileBuffer);
      const newHash = asyncGenerator.path;
      Swal.fire({
        title: "Nice !",
        text: "Please enter amount",
        input: "number",
        showCancelButton: true,
        cancelButtonText: "cancel",
        confirmButtonText: "Confirm",
        showLoaderOnConfirm: true,
        preConfirm: async (val) => {
          await sendTransaction(
            window.HostInstance,
            "addFiles",
            [
              hashTobytes(newHash),
              parseInt(val),
              { value: ethers.utils.parseEther(val) },
            ],
            "Your file has been successfully added"
          );
        },
      });
      setUpload(false);
    } catch (e) {
      Swal.fire("Oops...!", `${e}`, "error");
    }
  };

  const fetchDetail = async () => {
    // setSpin(true)
    const ipfshash = await window.HostInstance.details();
    const data = await axios.get(
      `https://ipfs.eraswap.cloud/ipfs/${bytesTohash(ipfshash)}`
    );
    console.log(data);
    setdetails(data.data);
    // setSpin(false)
  };

  useEffect(() => {
    (async () => {
      try {
        await fetchDetail();
      } catch {
        // setExist(false);
      }
    })();
  }, []);
  const Files = acceptedFiles.map((files: FileWithPath) => (
    <li key={files.path}>
      {files.path} - {files.size} bytes
    </li>
  ));
  return (
    <section className="services section-bg">
      <div className="row">
        <div className="col-lg-3">
          <Card className=" section-bg">
            <Card.Header style={{ backgroundColor: "#6610f2", color: "white" }}>
              {details?.Name}
            </Card.Header>
            <Card.Body>
              <Card.Title>Details</Card.Title>
              <Card.Text>
                <ul className="list-group">
                  <li className="list-group-item">
                    <b>IP : </b> {details.IP}
                  </li>
                  <li className="list-group-item">
                    <b>get API : </b> {details.getAPI}
                  </li>
                  <li className="list-group-item">
                    <b>set API : </b> {details.setAPI}
                  </li>
                  <li className="list-group-item">
                    <b>Peer ID : </b> {details.peerID}
                  </li>
                  <li className="list-group-item">
                    <b>Description : </b> {details.Description}
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
            <Card.Footer className="text-muted text-center">
              {Hostaddress}
            </Card.Footer>
          </Card>
        </div>

        <div className="col-9">
          <div className="btn-purple">
            <button className="purple-btn" onClick={() => setnext(false)}>
              Upload File
            </button>
            <button className="purple-btn ml-2" onClick={() => setnext(true)}>
              {" "}
              See Recent Files{" "}
            </button>
          </div>
          {next ? (
            <Recent />
          ) : (
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>See your all files </h2>
              </div>
              <h4>Upload a new File</h4>
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <p
                  className="dropzone"
                  style={{
                    width: "100%",
                    height: "300px",
                    border: "2px dashed #6610f2",
                    textAlign: "center",
                    lineHeight: "300px",
                  }}
                >
                  Drag & drop some files here, or click to select files
                </p>
              </div>
              <ul>{Files}</ul>
              <button
                className="purple-btn"
                disabled={spin}
                onClick={uploadFile}
              >
                {upload ? "Uploading ..." : " Upload File"}
              </button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
