import React, { useEffect, useState } from "react";
import { Accordion, Button, Table } from "react-bootstrap";
import { sendTransaction } from "../../components/TransactionModal/sendTransaction";
import { HostFactory } from "../../ethereum/HostFactory";
import { bytesTohash, hashTobytes } from "../../utils";

export default function EventFilter() {
  const [disputes, setDisputes] = useState([]);

  const ApproveDispute = async (i: number, result: boolean) => {
    window.HostInstance = HostFactory.connect(
      disputes[i][0],
      window.providerESN
    );
    await sendTransaction(
      window.HostInstance,
      "resolveDispute",
      [hashTobytes(disputes[i][1]), result],
      "Your Dispute has been accepted"
    );
  };

  // const fetchDetail = async (ipfshash:string) => {
  //   setSpin(true)
  //   const data = await axios.get(`https://ipfs.eraswap.cloud/ipfs/${bytesTohash(ipfshash)}`);
  //   console.log(data);
  //   setdetails(data.data);
  //   setSpin(false)
  // }
  const fetchDispute = async () => {
    const filter = window.ESCloudInstance.filters.RaisedDispute(null, null);
    const logs = await window.ESCloudInstance.queryFilter(filter);
    const parseLogs = logs.map((log) =>
      window.ESCloudInstance.interface.parseLog(log)
    );
    const disputeAll = parseLogs.map((ele) => ele.args);
    console.log("All :", disputeAll);
    const detailsAll = await Promise.all(
      disputeAll.map(async (ele) => {
        const statusArr = ["noDispute", "Processed", "Dismissed", "Refunded"];
        window.HostInstance = HostFactory.connect(ele[0], window.providerESN);
        const x = await window.HostInstance.disputes(ele[1]);
        const status = statusArr[x];
        // const p = [x[0], x[1], x[2], x[3], x[4], ele];
        return [ele[0], bytesTohash(ele[1]), status];
      })
    );
    setDisputes(detailsAll);
    // this.setState({ ...this.state, allSurvey: detailsAll });
    console.log("All :", detailsAll);
    // return surveyAll;
  };

  useEffect(() => {
    (async () => {
      try {
        await fetchDispute();
      } catch {
        // setExist(false);
      }
    })();
  }, []);

  return (
    <div>
      <h1 className="join-head">All Disputes</h1>
      <Accordion>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Files</th>
              <th>Status</th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {disputes.map((ele, index) => {
              return (
                <tr>
                  <td>{ele[1]}</td>
                  <td>{ele[2]}</td>
                  <td>
                    <a href={"https://ipfs.eraswap.cloud/ipfs/" + ele[1]}>
                      <i className="fas fa-link"></i>
                    </a>
                  </td>
                  <td>
                    <Button
                      variant="success"
                      onClick={() => ApproveDispute(index, true)}
                    >
                      Approve
                    </Button>{" "}
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      onClick={() => ApproveDispute(index, false)}
                    >
                      Reject
                    </Button>{" "}
                  </td>
                </tr>
              );
            })}{" "}
          </tbody>
        </Table>
        `
      </Accordion>
    </div>
  );
}
