import React, { Component } from "react";
import { sendTransaction } from "../../components/TransactionModal/sendTransaction";
import { hashTobytes } from "../../utils";
import ipfs from "./ipfs";

type Props = {};
type allDetails = {
  Name: string;
  IP: string;
  peerID: string;
  getAPI: string;
  setAPI: string;
  Description: string;
};
type State = {
  next: boolean;
  Details: allDetails;
};

export class JointHost extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      next: false,
      Details: {
        Name: "",
        IP: "",
        peerID: "",
        getAPI: "",
        setAPI: "",
        Description: "",
      },
    };
  }
  loadIPFS = async () => {
    console.log(JSON.stringify(this.state.Details));

    let asyncGenerator = await ipfs.add(JSON.stringify(this.state.Details));
    console.log(asyncGenerator);
    return asyncGenerator.path;
  };

  joinHost = async (e) => {
    e.preventDefault();
    const add = window.wallet?.address
      ? window.wallet.address
      : await window.wallet?.getAddress();
    const hash = await this.loadIPFS();
    const byte = hashTobytes(hash);
    await sendTransaction(
      window.ESCloudInstance,
      "newHost",
      [add, byte],
      "Your Application is under review"
    );
  };

  render() {
    return (
      <div>
        {!this.state.next ? (
          <section id="faq" className="faq">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>Join Us </h2>
                <p>
                  ESCloud IPFS network can be easily set up on Ubuntu OS.
                  <br /> you can use Cloud services to connected with our
                  network.
                </p>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="faq-list">
                    <ul>
                      <li data-aos="fade-up">
                        <i className="bx bx-check-circle icon-help"></i>{" "}
                        <a
                          data-toggle="collapse"
                          className="collapse"
                          href="#faq-list-1"
                        >
                          Step 1{" "}
                          <i className="bx bx-chevron-down icon-show"></i>
                          <i className="bx bx-chevron-up icon-close"></i>
                        </a>
                        <div
                          id="faq-list-1"
                          className="collapse"
                          data-parent=".faq-list"
                        >
                          <p>
                            <b>Getting started with EC2 :</b> First, pop on over
                            to aws.amazon.com/free/ and Create a Free Account .
                            When you hit Continue, it’ll take you to a page to
                            fill in your contact information. I setup a Personal
                            account.
                          </p>
                          <img
                            src="assets/img/step1.png"
                            alt=""
                            className="img-fluid mt10 mb10"
                          />
                        </div>
                      </li>
                      <li data-aos="fade-up" data-aos-delay="200">
                        <i className="bx bx-check-circle icon-help"></i>{" "}
                        <a
                          data-toggle="collapse"
                          href="#faq-list-2"
                          className="collapsed"
                        >
                          Step 2<i className="bx bx-chevron-down icon-show"></i>
                          <i className="bx bx-chevron-up icon-close"></i>
                        </a>
                        <div
                          id="faq-list-2"
                          className="collapse"
                          data-parent=".faq-list"
                        >
                          <p>
                            <b>
                              create our basic free-tier AWS EC2 instance :{" "}
                            </b>
                            Click on Services {">"}
                            Compute {">"} EC2 . You’ll end up on a page listing
                            the Amazon EC2 resources you are currently using.
                            Right now, it’s probably just 1 Security Group . So
                            let’s change that, and add an EC2 instsance by
                            clicking on Launch Instance .
                          </p>
                          <img
                            src="assets/img/step2.png"
                            alt=""
                            className="img-fluid mt10 mb10"
                          />
                          <img
                            src="assets/img/step2.1.png"
                            alt=""
                            className="img-fluid mt10 mb10"
                          />
                        </div>
                      </li>
                      <li data-aos="fade-up" data-aos-delay="300">
                        <i className="bx bx-check-circle icon-help"></i>{" "}
                        <a
                          data-toggle="collapse"
                          href="#faq-list-3"
                          className="collapsed"
                        >
                          Step 3<i className="bx bx-chevron-down icon-show"></i>
                          <i className="bx bx-chevron-up icon-close"></i>
                        </a>
                        <div
                          id="faq-list-3"
                          className="collapse"
                          data-parent=".faq-list"
                        >
                          <p>
                            {" "}
                            <b>
                              {" "}
                              create our basic free-tier AWS EC2 instance :{" "}
                            </b>{" "}
                          </p>
                          <img
                            src="assets/img/step3.png"
                            alt=""
                            className="img-fluid mt10 mb10"
                          />
                        </div>
                      </li>
                      <li data-aos="fade-up" data-aos-delay="400">
                        <i className="bx bx-check-circle icon-help"></i>{" "}
                        <a
                          data-toggle="collapse"
                          href="#faq-list-4"
                          className="collapsed"
                        >
                          Step 4<i className="bx bx-chevron-down icon-show"></i>
                          <i className="bx bx-chevron-up icon-close"></i>
                        </a>
                        <div
                          id="faq-list-4"
                          className="collapse"
                          data-parent=".faq-list"
                        >
                          <p>
                            Add User data: now we’re setting up user data which
                            will run on instance at the time of deployment. In{" "}
                            <b>Configure Instance</b> you have to add user data
                            in <b>advanced Details </b>
                          </p>
                          <p>
                            Go here and copy code inside setUp.sh from{" "}
                            <a
                              href="https://github.com/KMPARDS/esCloud-spec/blob/master/setUP.h"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              here{" "}
                            </a>
                          </p>
                          <p>
                            Then click on <b>Next:Add Storage </b>
                          </p>
                          <img
                            src="assets/img/step4.png"
                            alt=""
                            className="img-fluid mt10 mb10"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="faq-list">
                    <ul>
                      <li data-aos="fade-up">
                        <i className="bx bx-check-circle icon-help"></i>{" "}
                        <a
                          data-toggle="collapse"
                          className="collapse"
                          href="#faq-list-5"
                        >
                          Step 5{" "}
                          <i className="bx bx-chevron-down icon-show"></i>
                          <i className="bx bx-chevron-up icon-close"></i>
                        </a>
                        <div
                          id="faq-list-5"
                          className="collapse"
                          data-parent=".faq-list"
                        >
                          <p>
                            <b>Add Storage : </b>Add storage according to your
                            need
                          </p>
                          <img
                            src="assets/img/step5.png"
                            alt=""
                            className="img-fluid mt10 mb10"
                          />
                        </div>
                      </li>
                      <li data-aos="fade-up">
                        <i className="bx bx-check-circle icon-help"></i>{" "}
                        <a
                          data-toggle="collapse"
                          className="collapse"
                          href="#faq-list-6"
                        >
                          Step 6{" "}
                          <i className="bx bx-chevron-down icon-show"></i>
                          <i className="bx bx-chevron-up icon-close"></i>
                        </a>
                        <div
                          id="faq-list-6"
                          className="collapse"
                          data-parent=".faq-list"
                        >
                          <p>
                            {" "}
                            <b>Configure Security groups :</b> Next, skip over
                            Add Tags , and go straight to Configure Security
                            Group . By default, SSH connections on port 22 are
                            enabled from any source. This is probably fine,
                            though restricting access to known IP addresses is
                            probably a good idea. We’ll add some rules here (and
                            maybe change the group name).
                          </p>
                          <img
                            src="assets/img/step5.1.png"
                            alt=""
                            className="img-fluid mt10 mb10"
                          />
                        </div>
                      </li>
                      <li data-aos="fade-up" data-aos-delay="200">
                        <i className="bx bx-check-circle icon-help"></i>{" "}
                        <a
                          data-toggle="collapse"
                          href="#faq-list-7"
                          className="collapsed"
                        >
                          Step 7{" "}
                          <i className="bx bx-chevron-down icon-show"></i>
                          <i className="bx bx-chevron-up icon-close"></i>
                        </a>
                        <div
                          id="faq-list-7"
                          className="collapse"
                          data-parent=".faq-list"
                        >
                          <p>
                            <b>(optional) </b>This will bring up the dialog to
                            create a key new pair. So select create a new key
                            pair , name it something useful like ipfs , and
                            download the pem file ( your browser might
                            automatically append .txt to the downloaded file
                            name, so you’ll want to remove this before
                            subsequent steps ). Then click Launch Instances . If
                            you hadn’t setup billing alerts previously, you can
                            do it now by clicking on the links.
                          </p>
                          <img
                            src="assets/img/step6.png"
                            alt=""
                            className="img-fluid mt10 mb10"
                          />
                        </div>
                      </li>
                      <li data-aos="fade-up" data-aos-delay="300">
                        <i className="bx bx-check-circle icon-help"></i>{" "}
                        <a
                          data-toggle="collapse"
                          href="#faq-list-8"
                          className="collapsed"
                        >
                          Step 8<i className="bx bx-chevron-down icon-show"></i>
                          <i className="bx bx-chevron-up icon-close"></i>
                        </a>
                        <div
                          id="faq-list-8"
                          className="collapse"
                          data-parent=".faq-list"
                        >
                          <p>
                            While you wait for your instance to launch, you can
                            View Instances , or read up on{" "}
                            <a
                              href="https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/AccessingInstances.html?icmpid=docs_ec2_console"
                              target=""
                            >
                              how to connect to your Linux instance .{" "}
                            </a>
                          </p>
                          {/*  eslint-disable-next-line jsx-a11y/accessible-emoji */}
                          <p>Hurray 🎉🎉🎉</p>
                          <p>You have successfully join our network </p>
                          <p>
                            If you opened up your gateway, you should now be
                            able to browse to its address in a browser, and ask
                            for the docs directory:
                          </p>
                          <p>
                            http://ec2-xx-xxx-xxx-xx.us-xxxx-2.compute.amazonaws.com:8080/ipfs/QmS4ustL54uo8F
                            zR9455qaxZwuMiUhyvMcX9Ba8nUH4uVv , or anything else
                            accessible on the decentralized web.
                          </p>
                          <img
                            src="assets/img/step7.png"
                            alt=""
                            className="img-fluid mt10 mb10"
                          />
                        </div>
                      </li>
                    </ul>
                    <div id="host" className="btn-cloud-center">
                      <button
                        onClick={() => this.setState({ next: true })}
                        className="btn-cloud-txt"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section id="faq" className="faq">
            <div className="container" data-aos="fade-up">
              <div className="row">
                <div className="col-lg-12 col-md-8">
                  <div className="container-white">
                    <div className="joint-cloud-flex">
                      <img
                        src="assets/img/logocopy.png"
                        className="img-joint"
                        alt="logo"
                      />
                    </div>
                    <h1 className="join-head">JOIN AS A HOST</h1>

                    <div className="row">
                      <div className="col-lg-3"></div>
                      <div className="col-lg-6">
                        <div>
                          {" "}
                          <label className="label-txt">Name</label>
                        </div>
                        <input
                          onChange={(e) => {
                            this.setState({
                              Details: {
                                ...this.state.Details,
                                Name: e.target.value,
                              },
                            });
                          }}
                          className="form-control form-control-lg"
                          type="text"
                          placeholder=""
                        />

                        <div>
                          {" "}
                          <label className="label-txt">Your IP Address</label>
                        </div>
                        <input
                          className="form-control form-control-lg"
                          onChange={(e) => {
                            this.setState({
                              Details: {
                                ...this.state.Details,
                                IP: e.target.value,
                              },
                            });
                          }}
                          type="text"
                          placeholder=""
                        />

                        <div>
                          {" "}
                          <label className="label-txt">Peer ID</label>
                        </div>
                        <input
                          className="form-control form-control-lg"
                          onChange={(e) => {
                            this.setState({
                              Details: {
                                ...this.state.Details,
                                peerID: e.target.value,
                              },
                            });
                          }}
                          type="text"
                          placeholder=""
                        />
                        <div>
                          {" "}
                          <label className="label-txt">
                            get API (for port 8080)
                          </label>
                        </div>
                        <input
                          className="form-control form-control-lg"
                          onChange={(e) => {
                            this.setState({
                              Details: {
                                ...this.state.Details,
                                getAPI: e.target.value,
                              },
                            });
                          }}
                          type="text"
                          placeholder=""
                        />
                        <div>
                          {" "}
                          <label className="label-txt">
                            set API (for port 5001)
                          </label>
                        </div>
                        <input
                          className="form-control form-control-lg"
                          onChange={(e) => {
                            this.setState({
                              Details: {
                                ...this.state.Details,
                                setAPI: e.target.value,
                              },
                            });
                          }}
                          type="text"
                          placeholder=""
                        />

                        <div>
                          {" "}
                          <label className="label-txt">Description</label>
                        </div>
                        <textarea
                          id="w3review"
                          rows={5}
                          className="form-control form-control-lg"
                          onChange={(e) => {
                            this.setState({
                              Details: {
                                ...this.state.Details,
                                Description: e.target.value,
                              },
                            });
                          }}
                          name="w3review"
                        />
                        <div className="form-text text-muted">
                          For Style use{" "}
                          <a
                            className="text-primary"
                            href="https://guides.github.com/pdfs/markdown-cheatsheet-online.pdf"
                          >
                            MarkDown
                          </a>{" "}
                          or embed code
                        </div>

                        <div className="btn-flex-purple">
                          <button
                            className="purple-btn"
                            onClick={this.joinHost}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    );
  }
}
