import React from "react";

export const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-6 footer-contact">
              <img
                src="assets/img/logo.png"
                className="img-fluid animated"
                alt=""
                width="90"
              />
              <p className="mt10">
                ESCloud is a IPFS based decentralized & peer-to-peer network for
                storing and sharing data in a distributed file system.ESCloud is
                making more decentralized and secure all eraswap based DApps.
              </p>
              <ul className="social-menu mt20">
                <li>
                  <a
                    href="https://www.facebook.com/eraswap"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/eraswap/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-linkedin"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/eraswap/?hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>{" "}
                </li>
                <li>
                  <a
                    href="https://twitter.com/EraSwapTech"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://medium.com/@eraswap"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-medium"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://eraswap.tumblr.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-tumblr"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://t.me/eraswap"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-telegram"></i>
                  </a>
                </li>
                <li>
                  {" "}
                  <a
                    href="https://github.com/KMPARDS"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-github"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.reddit.com/user/EraSwap"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-reddit"></i>
                  </a>{" "}
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCGCP4f5DF1W6sbCjS6y3T1g?view_as=subscriber"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-youtube"></i>
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 footer-links">
              <h4>Main Links</h4>
              <ul>
                <li>
                  <i className="bx bx-chevron-right"></i> <a href="/">Home</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a href="/">Why ESCloud</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a href="/">How it works</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a href="/">How to join ESCloud</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a href="/">Features</a>
                </li>
              </ul>
            </div>

            <div className="col-lg-4 col-md-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://eraswaptoken.io/pdf/eraswap_whitepaper.pdf"
                  >
                    ES White Paper
                  </a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://eraswaptoken.io/pdf/era-swap-howey-test-letter-august7-2018.pdf"
                  >
                    Howey Test
                  </a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://eraswaptoken.io/pdf/es-statuary-warning.pdf"
                  >
                    Statuary Warning
                  </a>
                </li>

                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="about.htmhttp://eraswaptoken.io/pdf/es-statuary-warning.pdf"
                  >
                    Era Swap Terms &amp; Conditins
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="copyright-wrap d-md-flex py-4">
          <div className="mr-md-auto text-center text-md-left">
            <div className="copyright">
              © Eraswap 2020 | All rights reserved
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
