import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Card, Spinner, Table } from "react-bootstrap";
import { bytesTohash } from "../../utils";
import axios from "axios";
import { sendTransaction } from "../../components/TransactionModal/sendTransaction";

export default function HostApprove() {
  const [spin, setSpin] = useState(true);
  const [host, setHost] = useState([]);
  const [details, setdetails] = useState({
    Name: "",
    IP: "",
    peerID: "",
    getAPI: "",
    setAPI: "",
    Description: "",
  });

  const ApproveHost = async (i: number) => {
    await sendTransaction(
      window.ESCloudInstance,
      "approveHost",
      [host[i][0], host[i][1]],
      "host has been created successfully"
    );
  };

  const fetchDetail = async (ipfshash: string) => {
    setSpin(true);
    const data = await axios.get(
      `https://ipfs.eraswap.cloud/ipfs/${bytesTohash(ipfshash)}`
    );
    console.log(data);
    setdetails(data.data);
    setSpin(false);
  };
  const fetchHost = async () => {
    const filter = window.ESCloudInstance.filters.ApplyHost(null, null);
    const logs = await window.ESCloudInstance.queryFilter(filter);
    const parseLogs = logs.map((log) =>
      window.ESCloudInstance.interface.parseLog(log)
    );
    const hostAll = parseLogs.map((ele) => ele.args);
    console.log("All :", hostAll);
    const detailsAll = await Promise.all(
      hostAll.map(async (ele) => {
        const x = await window.ESCloudInstance.host(ele[0]);
        const status =
          ethers.constants.AddressZero === x ? "Not Approved" : "Approved";
        // const p = [x[0], x[1], x[2], x[3], x[4], ele];
        return [ele[0], ele[1], status];
      })
    );
    setHost(detailsAll);
    // this.setState({ ...this.state, allSurvey: detailsAll });
    console.log("All :", detailsAll);
    // return surveyAll;
  };

  useEffect(() => {
    (async () => {
      try {
        await fetchHost();
      } catch {
        // setExist(false);
      }
    })();
  }, []);

  return (
    <div>
      <h1 className="join-head">HOST Requests</h1>

      <Accordion>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>User Address</th>
              <th>Status</th>
              <th>details</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {host.map((ele, index) => {
              return (
                <>
                  <tr>
                    <td>{ele[0]}</td>
                    <td>{ele[2]}</td>
                    <td>
                      <Accordion.Toggle
                        as={Button}
                        onClick={() => fetchDetail(ele[1])}
                        variant="link"
                        eventKey={index.toString()}
                      >
                        See Details
                      </Accordion.Toggle>
                    </td>
                    <td>
                      <Button
                        variant="success"
                        onClick={() => ApproveHost(index)}
                      >
                        Approve
                      </Button>{" "}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4}>
                      <Accordion.Collapse eventKey={index.toString()}>
                        {spin ? (
                          <Spinner
                            className="mx-auto"
                            animation="border"
                            variant="primary"
                          />
                        ) : (
                          <Card style={{ width: "38rem", margin: "auto" }}>
                            <Card.Header>{details?.Name}</Card.Header>
                            <Card.Body>
                              <Card.Title>Details</Card.Title>
                              <Card.Text>
                                <ul className="list-group">
                                  <li className="list-group-item">
                                    <b>IP : </b> {details.IP}
                                  </li>
                                  <li className="list-group-item">
                                    <b>get API : </b> {details.getAPI}
                                  </li>
                                  <li className="list-group-item">
                                    <b>set API : </b> {details.setAPI}
                                  </li>
                                  <li className="list-group-item">
                                    <b>Peer ID : </b> {details.peerID}
                                  </li>
                                  <li className="list-group-item">
                                    <b>Description : </b> {details.Description}
                                  </li>
                                </ul>
                              </Card.Text>
                            </Card.Body>
                            <Card.Footer className="text-muted text-center">
                              {ele[0]}
                            </Card.Footer>
                          </Card>
                        )}
                      </Accordion.Collapse>
                    </td>
                  </tr>
                </>
              );
            })}{" "}
          </tbody>
        </Table>
        `
      </Accordion>
    </div>
  );
}
