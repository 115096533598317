import React from "react";
import { Link } from "react-router-dom";
import ConnectWallet from "./ConnectWallet";
export const Navbar = () => {
  return (
    <header id="header" className="fixed-top header-scrolled">
      <div className="container-fluid">
        <nav className="navbar navbar-expand-md navbar-dark bg-transparent sidebarNavigation ">
          <div className="container-fluid">
            <h1 className="logo mr-auto">
              <a href="/">
                <img
                  src={process.env.PUBLIC_URL + "/assets/img/logo.png"}
                  className="img-fluid animated"
                  alt=""
                />
              </a>
            </h1>
            <button
              className="navbar-toggler leftNavbarToggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarsExampleDefault"
              aria-controls="navbarsExampleDefault"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarsExampleDefault"
            >
              <ul className="nav navbar-nav nav-flex-icons ml-auto nav-menu">
                <li className="nav-item active">
                  <a href="/" className="nav-link">
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <Link to="/HostPanel" className="nav-link">
                    Host
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/All" className="nav-link">
                    Upload Files
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/join-host" className="nav-link">
                    Join as Host
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/Admin" className="nav-link">
                    Admin
                  </Link>
                </li>
                <ConnectWallet />
                {/* <li><a href="https://eraswap.life/" target="_blank" className="get-started-btn scrollto">Connect To Wallet</a></li> */}
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};
