import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import { Dropdown, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { HostFactory } from "../../ethereum/HostFactory";
import { bytesTohash } from "../../utils";
import Swal from "sweetalert2";
import { sendTransaction } from "../../components/TransactionModal/sendTransaction";

export default function Recent() {
  const { Hostaddress } = useParams() as { Hostaddress: string };
  window.HostInstance = HostFactory.connect(Hostaddress, window.providerESN);
  const [iswallet, setIswallet] = useState(false);
  const [files, setFiles] = useState([]);

  const fetchFiles = async () => {
    const address = window?.wallet.address
      ? window.wallet.address
      : await window.wallet.getAddress();
    const filter = window.HostInstance.filters.newFile(address, null, null);
    const logs = await window.HostInstance.queryFilter(filter);
    const parseLogs = logs.map((log) =>
      window.HostInstance.interface.parseLog(log)
    );
    const hostAll = parseLogs.map((ele) => ele.args);
    console.log("All :", hostAll);
    const detailsAll = await Promise.all(
      hostAll.map(async (ele) => {
        const dateTime = ele[2].toNumber();
        const date = new Date(dateTime * 1000);
        return [ele[1], date.toDateString()];
      })
    );
    setFiles(detailsAll);
    // this.setState({ ...this.state, allSurvey: detailsAll });
    console.log("All :", detailsAll);
    // return surveyAll;
  };

  const RenewFile = async (file: string) => {
    Swal.fire({
      title: "Nice !",
      text: "Please enter amount",
      input: "number",
      showCancelButton: true,
      cancelButtonText: "cancel",
      confirmButtonText: "Confirm",
      showLoaderOnConfirm: true,
      preConfirm: async (val) => {
        await sendTransaction(
          window.HostInstance,
          "renewFile",
          [file, parseInt(val), { value: ethers.utils.parseEther(val) }],
          "Your file has been successfully renewed"
        );
      },
    });
  };

  const RemoveFile = async (file: string) => {
    await sendTransaction(
      window.HostInstance,
      "removeFile",
      [file, { value: ethers.utils.parseEther("0") }],
      "Your file has been successfully removed"
    );
  };
  const DisputeFile = async (file: string) => {
    await sendTransaction(
      window.HostInstance,
      "disputeFile",
      [file],
      "You have successfully raised  dispute. your Dispute will resolved soon "
    );
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (window.wallet) {
        setIswallet(true);
        clearInterval(intervalId);
      } else setIswallet(false);
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await fetchFiles();
      } catch {
        // setExist(false);
      }
    })();
  }, []);

  return (
    <React.Fragment>
      <br />
      <br />
      <br />
      <h4>Your files </h4>
      {!iswallet ? (
        <div
          className="order-1 order-lg-2 hero-img section-title mx-auto"
          data-aos="zoom-in"
        >
          <img
            src={process.env.PUBLIC_URL + "/assets/img/connectwallet.png"}
            className="img-fluid animated"
            alt=""
          />
        </div>
      ) : (
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>File</th>
                <th>Valid Till</th>
                <th>Open</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {files.map((ele, index) => {
                return (
                  <>
                    <tr>
                      <td>{bytesTohash(ele[0])}</td>
                      <td>{ele[1]}</td>
                      <td>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`https://ipfs.eraswap.cloud/ipfs/${bytesTohash(
                            ele[0]
                          )}`}
                        >
                          <i className="fa fa-link" aria-hidden="true"></i>
                        </a>
                      </td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle as="div">
                            <i
                              className="fa fa-ellipsis-v"
                              aria-hidden="true"
                            ></i>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={async () => await RenewFile(ele[0])}
                            >
                              <i className="fa fa-refresh"></i> Renew
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={async () => await RemoveFile(ele[0])}
                            >
                              <i className="fa fa-trash"></i> Remove
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={async () => await DisputeFile(ele[0])}
                            >
                              <i className="fas fa-diagnoses"></i> Dispute
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={4}></td>
                    </tr>
                  </>
                );
              })}{" "}
            </tbody>
          </Table>
          `
        </>
      )}
    </React.Fragment>
  );
}
