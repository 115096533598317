import React, { useEffect, useState } from "react";
import { Accordion, Button, Card, Spinner, Table } from "react-bootstrap";
import { bytesTohash } from "../../utils";
import axios from "axios";
import { Link } from "react-router-dom";

export default function HostApprove() {
  const [spin, setSpin] = useState(true);
  const [host, setHost] = useState([]);
  const [details, setdetails] = useState({
    Name: "",
    IP: "",
    peerID: "",
    getAPI: "",
    setAPI: "",
    Description: "",
  });

  const fetchDetail = async (ipfshash: string) => {
    setSpin(true);
    const data = await axios.get(
      `https://ipfs.eraswap.cloud/ipfs/${bytesTohash(ipfshash)}`
    );
    console.log(data);
    setdetails(data.data);
    setSpin(false);
  };
  const fetchHost = async () => {
    const filter = window.ESCloudInstance.filters.NewHost(null, null, null);
    const logs = await window.ESCloudInstance.queryFilter(filter);
    const parseLogs = logs.map((log) =>
      window.ESCloudInstance.interface.parseLog(log)
    );
    const hostAll = parseLogs.map((ele) => ele.args);
    console.log("All :", hostAll);
    const detailsAll = hostAll;
    setHost(detailsAll);
    // this.setState({ ...this.state, allSurvey: detailsAll });
    console.log("All :", detailsAll);
    // return surveyAll;
  };

  useEffect(() => {
    (async () => {
      try {
        await fetchHost();
      } catch {
        // setExist(false);
      }
    })();
  }, []);

  return (
    <section id="faq" className="faq">
      <div className="container" data-aos="fade-up">
        <div className="row">
          <div className="col-lg-12 col-md-9">
            <div className="container-white">
              <div className="joint-cloud-flex">
                <img
                  src="assets/img/logocopy.png"
                  className="img-joint"
                  alt="logo"
                />
              </div>
              <br />
              <div>
                <h1 className="join-head">All HOST </h1>

                <Accordion>
                  <Table striped bordered hover size="sm">
                    <thead>
                      <tr>
                        <th>User Address</th>
                        <th>ContractAddress</th>
                        <th>details</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {host.map((ele, index) => {
                        return (
                          <>
                            <tr>
                              <td>{ele[1]}</td>
                              <td>{ele[0]}</td>
                              <td>
                                <Accordion.Toggle
                                  as={Button}
                                  onClick={() => fetchDetail(ele[2])}
                                  variant="link"
                                  eventKey={index.toString()}
                                >
                                  See Details
                                </Accordion.Toggle>
                              </td>
                              <td>
                                <Link to={"/Host/" + ele[0]}>
                                  <Button variant="success">Go to here </Button>{" "}
                                </Link>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={4}>
                                <Accordion.Collapse eventKey={index.toString()}>
                                  {spin ? (
                                    <Spinner
                                      className="mx-auto"
                                      animation="border"
                                      variant="primary"
                                    />
                                  ) : (
                                    <Card
                                      style={{ width: "38rem", margin: "auto" }}
                                    >
                                      <Card.Header>{details?.Name}</Card.Header>
                                      <Card.Body>
                                        <Card.Title>Details</Card.Title>
                                        <Card.Text>
                                          <ul className="list-group">
                                            <li className="list-group-item">
                                              <b>IP : </b> {details.IP}
                                            </li>
                                            <li className="list-group-item">
                                              <b>get API : </b> {details.getAPI}
                                            </li>
                                            <li className="list-group-item">
                                              <b>set API : </b> {details.setAPI}
                                            </li>
                                            <li className="list-group-item">
                                              <b>Peer ID : </b> {details.peerID}
                                            </li>
                                            <li className="list-group-item">
                                              <b>Description : </b>{" "}
                                              {details.Description}
                                            </li>
                                          </ul>
                                        </Card.Text>
                                      </Card.Body>
                                      <Card.Footer className="text-muted text-center">
                                        {ele[0]}
                                      </Card.Footer>
                                    </Card>
                                  )}
                                </Accordion.Collapse>
                              </td>
                            </tr>
                          </>
                        );
                      })}{" "}
                    </tbody>
                  </Table>
                  `
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
